<template>
  <div class="container h-100">
    <!-- <div>all</div> -->
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-8">
        <div class="card card-custom shadow">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="mx-4 mt-5 mb-3">
                  <h4 class="text-center fw-bolder text-navy-blue">
                    Review Application
                  </h4>
                </div>
              </div>
              <div class="col-lg-7 my-5">
                <!-- {{ applicationData }} -->
                <div class="border rounded p-3">
                  <div class="mb-5 row">
                    <div class="mb-3 col-sm-12">
                      <div class="float-end">
                        <button
                          class="btn btn-sm btn-link"
                          @click.prevent="goToApplicationForm()"
                        >
                          Edit Info
                        </button>
                      </div>
                    </div>
                    <div class="mb-3 col-sm-4">
                      <label class="form-label">First Name</label>
                      <div class="diplayed-detail">
                        {{ applicationData.firstName }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-4">
                      <label class="form-label">Middle Name</label>
                      <div class="diplayed-detail">
                        {{
                          applicationData.middleName
                            ? applicationData.middleName
                            : ""
                        }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-4">
                      <label class="form-label">Last Name</label>
                      <div class="diplayed-detail">
                        {{ applicationData.lastName }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-4">
                      <label for="" class="form-label">Birthday</label>
                      <div class="diplayed-detail">
                        {{ birthdate }}
                      </div>
                    </div>

                    <!-- <div class="mb-3 col-sm-4">
                      <label for="" class="form-label">Gender</label>
                      <div class="diplayed-detail">
                        {{ applicationData.gender }}
                      </div>
                    </div> -->

                    <!-- <div class="mb-3 col-sm-4">
                      <label for="" class="form-label">Civil Status</label>
                      <div class="diplayed-detail">
                        {{ applicationData.civilStatus }}
                      </div>
                    </div> -->

                    <div class="mb-3 col-sm-4">
                      <label for="" class="form-label">Contact Number</label>
                      <div class="diplayed-detail">
                        {{ mobileNumber }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-4">
                      <label for="" class="form-label">Email Address</label>
                      <div class="diplayed-detail">
                        {{ applicationData.emailAddress }}
                      </div>
                    </div>

                    <!-- <div class="mb-3 col-sm-4">
                      <label for="" class="form-label"
                        >Mother's First Name</label
                      >
                      <div class="diplayed-detail">
                        {{ applicationData.motherFirstName }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-4">
                      <label for="" class="form-label"
                        >Mother's Last Name</label
                      >
                      <div class="diplayed-detail">
                        {{ applicationData.motherLastName }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-4">
                      <label for="" class="form-label"
                        >Mother's Middle Name</label
                      >
                      <div class="diplayed-detail">
                        {{ applicationData.motherMiddleName }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-12">
                      <label for="" class="form-label">Area</label>
                      <div class="diplayed-detail">
                        {{ applicationData.area }}
                      </div>
                    </div> -->

                    <div class="mb-3 col-sm-12">
                      <h6 class="title-header">Address</h6>
                      <div class="text-header-underline"></div>
                    </div>

                    <div class="mb-3 col-sm-6">
                      <label for="" class="form-label">Address</label>
                      <div class="diplayed-detail">
                        {{ applicationData.address }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-6">
                      <label for="" class="form-label">Village</label>
                      <div class="diplayed-detail">
                        {{ applicationData.village }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-6">
                      <label for="" class="form-label">Region</label>
                      <div class="diplayed-detail">
                        {{ applicationData.region }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-6">
                      <label for="" class="form-label">Province</label>
                      <div class="diplayed-detail">
                        {{ applicationData.province }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-6">
                      <label for="" class="form-label">City / Municipal</label>
                      <div class="diplayed-detail">
                        {{ applicationData.city }}
                      </div>
                    </div>

                    <div class="mb-3 col-sm-6">
                      <label for="" class="form-label">Barangay</label>
                      <div class="diplayed-detail">
                        {{ applicationData.barangay }}
                      </div>
                    </div>

                    <!-- <div class="mb-3 text-center">
                      <button
                        class="btn px-5 btn-s2s-blue"
                        :disabled="btnLoading"
                        @click.prevent="applyNow()"
                      >
                        <span
                          v-if="btnLoading"
                          class="spinner-border spinner-border-sm pl-5"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </span>
                        Submit
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="col-lg-5 my-5">
                <div class="breakdown-border">
                  <div class="row">
                    <div class="mb-3 col-sm-12">
                      <div class="float-end">
                        <button
                          class="btn btn-sm btn-link"
                          @click.prevent="showDataPack(true)"
                        >
                          Data Pack
                        </button>
                      </div>
                    </div>
                    <div class="col-sm-9 fw-bold">
                      {{ getDataPack.skuName }}
                    </div>
                    <div class="col-sm-3">
                      <div class="float-end">
                        <!-- {{ getSKU.items[0].price }} -->
                        {{ setToLocaleString(getDataPack.price) }}
                      </div>
                    </div>
                    <div class="col-sm-9">
                      <small
                        ><i>{{ getDataPack.description }}</i></small
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-9 fw-bold">Convenience Fee</div>
                    <div class="col-sm-3">
                      <div class="float-end">0.00</div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-7">Total</div>
                    <div class="col-sm-5">
                      <div class="float-end">
                        {{ setToLocaleString(getDataPack.price) }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-lg-12 text-end">
                  <div class="">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="isChecked"
                    />
                    <a href="#" @click.prevent="showTC()" class="ms-1"
                      >Terms & Conditions and Privacy Policy</a
                    >
                  </div>
                </div> -->

                <div class="col-lg-12 my-4">
                  <div class="mb-3">
                    <label class="form-label"
                      >Referral Code <small>(optional)</small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Referral Code"
                      name="refCode"
                      v-model="affCode"
                    />
                  </div>

                  <!-- <div class="mb-3">
                    <label class="form-label"
                      >Affiliate Mobile Number <small>(optional)</small></label
                    >
                    <input
                      v-mask="'09 ### ### ###'"
                      masked="false"
                      type="tel"
                      name="mobileNumber"
                      class="form-control"
                      placeholder="Affiliate Mobile Number"
                      v-model="affMobileNumber"
                    />
                  </div> -->

                  <div class="mb-3" v-if="getIsCod">
                    <label class="form-label"
                      >Select preferred date and time of installation</label
                    >
                    <div class="row">
                      <div class="col">
                        <input
                          type="date"
                          class="form-control"
                          placeholder="Preferred Date"
                          :min="minDate"
                          :max="maxDate"
                          v-model="preferredDate"
                          name="preferredDate"
                          required
                          :class="{
                            'is-invalid':
                              submitted && errors.has('preferredDate'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('preferredDate')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>
                      <div class="col">
                        <input
                          type="time"
                          class="form-control"
                          placeholder="Preferred Date"
                          v-model="preferredTime"
                          name="preferredTime"
                          required
                          :class="{
                            'is-invalid':
                              submitted && errors.has('preferredTime'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('preferredTime')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 text-center mt-5" v-if="!getIsCod">
                  <button
                    class="btn px-5 btn-s2s-blue"
                    :disabled="btnLoading"
                    @click.prevent="payNow()"
                  >
                    <span
                      v-if="btnLoading"
                      class="spinner-border spinner-border-sm pl-5"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    Pay Now
                  </button>
                </div>

                <div class="col-lg-12 text-center mt-5" v-else>
                  <button
                    class="btn px-5 btn-s2s-blue"
                    :disabled="btnLoading"
                    @click.prevent="submitCod()"
                  >
                    <span
                      v-if="btnLoading"
                      class="spinner-border spinner-border-sm pl-5"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    Submit
                  </button>

                  <router-link to="/" class="btn btn-link ms-2"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DataPackModal ref="dataPackModal" />
    <TermsAndConditions ref="termsAndConditions" />
  </div>
</template>

<script>
// import { required } from "vuelidate/lib/validators";
import DataPackModal from "../components/DataPackModal.vue";
import TermsAndConditions from "../components/TermsAndConditions.vue";
import moment from "moment";
export default {
  components: {
    DataPackModal,
    TermsAndConditions,
  },

  data() {
    const d = new Date();
    const defaultDate = moment(d, "DD-MM-YYYY")
      .add(1, "days")
      .format("YYYY-MM-DD");
    const maxDate = moment(d, "DD-MM-YYYY").add(3, "days").format("YYYY-MM-DD");
    return {
      isChecked: false,
      setupFee: 500,
      loadingBtn: false,
      modalMaps: {
        isShow: false,
      },

      civilStatus: ["Single", "Married", "Divorced", "Widowed"],
      location: {
        regions: [],
        provinces: [],
        cities: [],
        barangay: [],
      },

      form: {
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "MALE",
        mobileNumber: "",
        emailAddress: "",
        address: "",
        barangay: "",
        city: "",
        region: "",
        province: "",
        citizenship: "Filipino",
        birthdate: "",
        civilStatus: "",
        motherFirstName: "",
        motherLastName: "",
        motherMiddleName: "",
        area: "",
        village: "",
      },

      submitted: false,
      btnLoading: false,
      affCode: "",
      // affMobileNumber: "",

      minDate: defaultDate,
      maxDate: maxDate,
      preferredDate: defaultDate,
      preferredTime: "10:00",
    };
  },

  methods: {
    async payNow() {
      this.submitted = true;
      this.btnLoading = true;

      const payload = { ...this.applicationData };
      payload.referenceNumber = this.getRefNumber;
      payload.mobileNumber = await this.reFormatNumber(payload.mobileNumber);
      // payload.affiliateCode = this.$store.getters.GET_AFFILIATECODE;

      await this.$api
        .post("/acquisition/customer", payload)
        .then((response) => {
          const res = response.data;

          if (res.status) {
            // this.$store
            //   .dispatch("ADD_REFNUMBER", res.data.referenceNumber)
            //   .then(() => {
            // this.$router.push({ path: "submitted" });
            // this.createJo();
            this.postPayment(this.getRefNumber);
            // });
          }
        })
        .catch((e) => {
          this.btnLoading = false;
          console.log(e);
        });
    },

    async submitCod() {
      this.submitted = true;
      this.btnLoading = true;

      const s = await this.$validator.validate().then((valid) => {
        return valid;
      });

      if (!s) {
        // this.toast("Please fill in all required fields.");
        this.btnLoading = false;
        return false;
      }

      let confirmNoReferral = false;
      let letsGo = true;

      if (!this.affCode) {
        // await this.$swal({
        //   title: "Confirmation?",
        //   text: "Are you sure you want to continue without affiliate code?",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonText: "Yes",
        //   cancelButtonText: "No",
        // }).then((result) => {
        //   if (!result.isConfirmed) {
        //     letsGo = false;
        //     this.submitted = false;
        //     this.btnLoading = false;
        //     return;
        //   }

        this.$store.dispatch("ADD_AFFILIATECODE", "");
        confirmNoReferral = true;
        // });
      }

      // console.log(s);
      if (!letsGo) {
        return;
      }

      this.$store.dispatch("ADD_AFFILIATECODE", this.affCode);

      const payload = { ...this.applicationData };
      const mobileNumber = payload.mobileNumber;
      payload.referenceNumber = this.GET_PREDATA.referenceNumber;
      payload.mobileNumber = await this.reFormatNumber(mobileNumber);
      // payload.affiliateCode = this.$store.getters.GET_AFFILIATECODE;

      await this.$api
        .post("/online/cod/customer", payload)
        .then((response) => {
          const res = response.data;

          if (!res.status) {
            this.$swal("Error", res.message, "error");
            this.btnLoading = false;
            return;
          }

          // if (res.status) {
          // this.$router.push({ path: "submitted" });
          // this.$store
          //   .dispatch("ADD_REFNUMBER", res.data.referenceNumber)
          //   .then(() => {

          // this.createJo();
          this.postPaymentCod(
            this.GET_PREDATA.referenceNumber,
            confirmNoReferral
          );
          // });
          // }
        })
        .catch((e) => {
          this.btnLoading = false;
          console.log(e);
        });
    },

    async createJo() {
      const refNumber = this.getRefNumber;

      await this.$api
        .post("/acquisition/create-job-order", {
          referenceNumber: refNumber,
        })
        .then((response) => {
          const res = response.data;
          // console.log(res);
          if (!res.status) {
            this.submitted = false;
            this.btnLoading = false;
            this.$swal("Failed", res.message, "error");
            return;
          }

          this.postPayment(refNumber);
        })
        .catch((e) => {
          this.btnLoading = false;
          console.log(e);
        });
    },

    async postPayment(refNumber) {
      const { origin } = window.location;
      let confirmNoReferral = false;
      let letsGo = true;

      if (!this.affCode) {
        // await this.$swal({
        //   title: "Confirmation?",
        //   text: "Are you sure you want to continue without affiliate code?",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonText: "Yes",
        //   cancelButtonText: "No",
        // }).then((result) => {
        //   if (!result.isConfirmed) {
        //     letsGo = false;
        //     return;
        //   }

        confirmNoReferral = true;
        this.$store.dispatch("ADD_AFFILIATECODE", "");
        // });
      }

      // console.log(s);
      if (!letsGo) {
        return;
      }

      this.$store.dispatch("ADD_AFFILIATECODE", this.affCode);

      const payload = {
        referenceNumber: refNumber,
        successUrl: `${origin}/submitted`,
        failedUrl: `${origin}/failed`,
        skuId: this.getDataPack.skuId,
        retailerId: this.applicationData.retailerId,
        // affiliateCode: this.$store.getters.GET_AFFILIATECODE,
        affiliateCode: this.affCode,
        // affiliateMobileNumber: this.affMobileNumber,
        confirmNoReferral: confirmNoReferral,
      };

      // console.log(payload);
      await this.$api
        .post("/acquisition/online-payment", payload)
        .then((response) => {
          this.submitted = false;
          this.btnLoading = false;
          const res = response.data;

          if (!res.status) {
            this.$swal("Failed", res.message, "error");
            return;
          }

          window.location = res.data.redirectUrl;
        })
        .catch((e) => {
          this.btnLoading = false;
          console.log(e);
        });
    },

    async postPaymentCod(refNumber, confirmNoReferral) {
      const payload = {
        referenceNumber: refNumber,
        sku: this.getDataPack.skuId,
        affiliateCode: this.affCode,
        // affiliateMobileNumber: this.affMobileNumber,
        confirmNoReferral: confirmNoReferral,
        installationDate: `${this.preferredDate} ${this.preferredTime}`,
      };

      await this.$api
        .post("/online/cod/payment", payload)
        .then((response) => {
          this.submitted = false;
          this.btnLoading = false;
          const res = response.data;

          if (!res.status) {
            this.$swal("Failed", res.message, "error");
            return;
          }

          this.$store
            .dispatch("ADD_COD_RESPONSE", res.data.codPayment)
            .then(() => {
              this.$router.push({ path: "submitted" });
            });

          // window.location = res.data.redirectUrl;
        })
        .catch((e) => {
          this.btnLoading = false;
          console.log(e);
        });
    },

    goToApplicationForm() {
      this.$router.push({ path: "application-form" });
    },

    reFormatNumber(num) {
      // return num.replace(/\s+/g, "").replace("0", "");
      if (num && num.indexOf("63") == 0) {
        return num.replace(/\s+/g, "");
      }
      return "63" + num.substring(1).replace(/\s+/g, "");
    },

    async showDataPack(force = false) {
      await this.$refs.dataPackModal.open(force);
    },

    setToLocaleString(val, decimal = 2) {
      if (val) {
        return parseFloat(val).toLocaleString("en-US", {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
          // return parseInt(val).toLocaleString(undefined, {
          //   minimumFractionDigits: decimal,
        });
      }
      return 0;
    },

    async showTC() {
      await this.$refs.termsAndConditions.open();
    },
  },

  created() {
    // this.showDataPack();
    // this.$store.dispatch("ADD_DATAPACK", {});

    if (this.getIsCod) {
      if (!this.getOTPVerified) {
        this.$router.push({ path: "application-form" });
        return;
      }
    }

    const appData = this.$store.getters.GET_APPLICATIONFORMDATA;

    const isEmpty = Object.keys(appData).length === 0;

    if (isEmpty) {
      this.$router.push({ path: "/" });
      return;
    }
    this.$nextTick(function () {
      // console.log(this.$refs.dataPackModal.open);
      this.showDataPack();
    });

    // this.getLocation();
    // const accountNum = localStorage.getItem("accNum");
    // if (accountNum) {
    //   this.accountNumber = accountNum;
    // }
  },

  mounted() {},

  computed: {
    applicationData() {
      return this.$store.getters.GET_APPLICATIONFORMDATA;
    },

    mobileNumber() {
      return this.$store.getters.GET_APPLICATIONFORMDATA.mobileNumber;
    },

    birthdate() {
      const bday = this.$store.getters.GET_APPLICATIONFORMDATA.birthdate;
      // return bday;
      const b = bday.split("-");
      return `${moment.monthsShort(b[1] - 1)}-${b[2]}`;
    },

    getRefNumber() {
      return this.$store.getters.GET_REFNUMBER;
    },

    getDataPack() {
      return this.$store.getters.GET_DATAPACK;
    },

    getAffiliateCode() {
      return this.$store.getters.GET_AFFILIATECODE;
    },

    getIsCod() {
      return this.$store.getters.GET_ISCOD;
    },

    GET_PREDATA() {
      return this.$store.getters.GET_PREDATA;
    },

    getOTPVerified() {
      return this.$store.getters.GET_OTPVERIFID;
    },
  },
};
</script>

<style scoped>
.breakdown-border {
  padding: 10px 10px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  border-left: 4px solid #e7196a;
}

.diplayed-detail {
  font-weight: bold;
  /* font-size: 1rem; */
}

input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-ms-clear {
  display: none;
}
</style>
